/**
 * @param {('anasayfaYeniIsim' | 'kullaniciGoruntule' | 'kullaniciEkle' | 'kullaniciSil' | 'kullaniciListesiCiktiAlma' | 'kullaniciIslemleriIzleme' | 'ilkDefaUcanlarSorgula' | 'yeniYolcuCiktiAlma' | 'yolcuIletisimBilgiler' | 'yolcuIletisimBilgilerCiktiAlma' | 'duyuruListesiGoruntule' | 'duyuruEkle' | 'duyuruOku' | 'ilkAcilisDuyuruOnayla' | 'ulkeListesiGoruntule' | 'ulkeEkle' | 'havalimaniListesiGoruntuleme' | 'havalimaniListesiEkleme' | 'islemTurleriYeniIsim' | 'mesajAkis' | 'mesajAkisCiktiAlma' | 'logKaydi' | 'ucusListesiYolculariGoster' | 'ucusListesiKBBGoster' | 'ucusListesiTakipliYolcuGoster' | 'yolcuSorgulama' | 'yolcuSorgulamaCiktiiAlma' | 'kacakcilikBilgiBankasi' | 'kacakcilikBilgiBankasiCiktiAlma' | 'kacakcilikBilgiBankasiYolcuDetayi' | 'kacakcilikBilgiBankasiYolcuBilgiKarti' | 'kacakcilikBilgiBankasiYolcuIslemleri' | 'kacakcilikBilgiBankasiOlayAciklama' | 'takibeTakilanYolcular' | 'takibeTakilanYolcularCiktiAlma' | 'takibeTakilanYolcularDetayGoruntuleme' | 'YolcuTakip' | 'YolcuTakipEkle' | 'YolcuTakipKaldir' | 'onayBekleyenlerListesi' | 'onayBekleyenlerListesiCiktiAlma' | 'onayBekleyenlerListesiOnaylamaYetkisi' | 'islemYapilanYolcularGoruntuleme' | 'islemYapilanYolcularSilme' | 'ZamanlanmisSorguListesiSorguEkleme' | 'ZamanlanmisSorguListesiCiktiAlma' | 'sikUcanYolcularSorgulama' | 'sikUcanYolcularCiktriAlma' | 'yolcuPuanlama' | 'destekTalebi'|'destekTalebiGuncelle' | 'havaTakip')} action - Type of the media
 */


export function getAction(action) {
    return action;
}




const restrictedMenus = ['map', 'approvalList', 'logList', 'messageList', 'announcement', 'userList', 'userProcess', 'firstTimeTraveller', 'transactionTypeList', 'userGroup', 'timedApprovalList']
export const checkNavPermittion = (key, userRole) => {
    if (restrictedMenus.includes(key)) {

        return userRole && permittionMap[userRole]?.menu?.includes(key)
    }
    return true
}
export const permittionMap = {
    "Admin": {
        "anasayfaYeniIsim": true,
        kullaniciGoruntule: true,
        "kullaniciEkle": true,
        "kullaniciSil": true,
        "kullaniciListesiCiktiAlma": true,
        kullaniciIslemleriIzleme: true,
        ilkDefaUcanlarSorgula: true,
        yeniYolcuCiktiAlma: true,
        yolcuIletisimBilgiler: true,
        yolcuIletisimBilgilerCiktiAlma: true,
        duyuruListesiGoruntule: true,
        duyuruEkle: true,
        duyuruOku: true,
        ilkAcilisDuyuruOnayla: true,
        ulkeListesiGoruntule: true,
        ulkeEkle: true,
        havalimaniListesiGoruntuleme: true,
        havalimaniListesiEkleme: true,
        islemTurleriYeniIsim: true,
        mesajAkis: true,
        mesajAkisCiktiAlma: true,
        logKaydi: true,
        ucusListesiYolculariGoster: true,
        ucusListesiKBBGoster: true,
        ucusListesiTakipliYolcuGoster: true,
        yolcuSorgulama: true,
        yolcuSorgulamaCiktiiAlma: true,
        kacakcilikBilgiBankasi: true,
        kacakcilikBilgiBankasiCiktiAlma: true,
        kacakcilikBilgiBankasiYolcuDetayi: true,
        kacakcilikBilgiBankasiYolcuBilgiKarti: true,
        kacakcilikBilgiBankasiYolcuBilgiKarti: true,
        kacakcilikBilgiBankasiYolcuIslemleri: true,
        kacakcilikBilgiBankasiOlayAciklama: true,
        takibeTakilanYolcular: true,
        takibeTakilanYolcularCiktiAlma: true,
        takibeTakilanYolcularDetayGoruntuleme: true,
        YolcuTakip: true,
        YolcuTakipEkle: true,
        YolcuTakipKaldir: true,
        onayBekleyenlerListesi: true,
        onayBekleyenlerListesiCiktiAlma: true,
        onayBekleyenlerListesiOnaylamaYetkisi: true,
        islemYapilanYolcularGoruntuleme: true,
        islemYapilanYolcularSilme: true,
        ZamanlanmisSorguListesiSorguEkleme: true,
        ZamanlanmisSorguListesiCiktiAlma: true,
        sikUcanYolcularSorgulama: true,
        sikUcanYolcularCiktriAlma: true,
        yolcuPuanlama: true,
        destekTalebi: true,
        destekTalebiGuncelle:true,
        havaTakip: true, //harita
        menu: ['map', 'approvalList', 'logList', 'messageList', 'announcement', 'userList', 'userProcess', 'firstTimeTraveller', 'transactionTypeList', 'userGroup', 'timedApprovalList']
    },
    "Yönetici": {
        "anasayfaYeniIsim": true,
        kullaniciGoruntule: false,
        "kullaniciEkle": false,
        "kullaniciSil": true,
        "kullaniciListesiCiktiAlma": false,
        kullaniciIslemleriIzleme: true,
        ilkDefaUcanlarSorgula: false,
        yeniYolcuCiktiAlma: false,
        yolcuIletisimBilgiler: true,
        yolcuIletisimBilgilerCiktiAlma: true,
        duyuruListesiGoruntule: true,
        duyuruEkle: false,
        duyuruOku: true,
        ilkAcilisDuyuruOnayla: true,
        ulkeListesiGoruntule: true,
        ulkeEkle: false,
        havalimaniListesiGoruntuleme: true,
        havalimaniListesiEkleme: false,
        islemTurleriYeniIsim: true,
        mesajAkis: true,
        mesajAkisCiktiAlma: false,
        logKaydi: true,
        ucusListesiYolculariGoster: true,
        ucusListesiKBBGoster: true,
        ucusListesiTakipliYolcuGoster: true,
        yolcuSorgulama: true,
        yolcuSorgulamaCiktiiAlma: true,
        kacakcilikBilgiBankasi: true,
        kacakcilikBilgiBankasiCiktiAlma: true,
        kacakcilikBilgiBankasiYolcuDetayi: true,
        kacakcilikBilgiBankasiYolcuBilgiKarti: true,
        kacakcilikBilgiBankasiYolcuBilgiKarti: true,
        kacakcilikBilgiBankasiYolcuIslemleri: true,
        kacakcilikBilgiBankasiOlayAciklama: true,
        takibeTakilanYolcular: true,
        takibeTakilanYolcularCiktiAlma: true,
        takibeTakilanYolcularDetayGoruntuleme: true,
        YolcuTakip: true,
        YolcuTakipEkle: true,
        YolcuTakipKaldir: true,
        onayBekleyenlerListesi: true,
        onayBekleyenlerListesiCiktiAlma: false,
        onayBekleyenlerListesiOnaylamaYetkisi: true,
        islemYapilanYolcularGoruntuleme: true,
        islemYapilanYolcularSilme: false,
        ZamanlanmisSorguListesiSorguEkleme: true,
        ZamanlanmisSorguListesiCiktiAlma: true,
        sikUcanYolcularSorgulama: true,
        sikUcanYolcularCiktriAlma: true,
        yolcuPuanlama: true,
        destekTalebi: true,
        destekTalebiGuncelle:false,
        havaTakip: true, //harita
        menu: ['approvalList', 'timedApprovalList']
    },
    "Kullanıcı": {
        "anasayfaYeniIsim": true,
        kullaniciGoruntule: false,
        "kullaniciEkle": false,
        "kullaniciSil": true,
        "kullaniciListesiCiktiAlma": false,
        kullaniciIslemleriIzleme: true,
        ilkDefaUcanlarSorgula: false,
        yeniYolcuCiktiAlma: false,
        yolcuIletisimBilgiler: true,
        yolcuIletisimBilgilerCiktiAlma: true,
        duyuruListesiGoruntule: true,
        duyuruEkle: false,
        duyuruOku: true,
        ilkAcilisDuyuruOnayla: true,
        ulkeListesiGoruntule: true,
        ulkeEkle: false,
        havalimaniListesiGoruntuleme: true,
        havalimaniListesiEkleme: false,
        islemTurleriYeniIsim: true,
        mesajAkis: true,
        mesajAkisCiktiAlma: false,
        logKaydi: true,
        ucusListesiYolculariGoster: true,
        ucusListesiKBBGoster: true,
        ucusListesiTakipliYolcuGoster: true,
        yolcuSorgulama: true,
        yolcuSorgulamaCiktiiAlma: true,
        kacakcilikBilgiBankasi: true,
        kacakcilikBilgiBankasiCiktiAlma: true,
        kacakcilikBilgiBankasiYolcuDetayi: true,
        kacakcilikBilgiBankasiYolcuBilgiKarti: true,
        kacakcilikBilgiBankasiYolcuBilgiKarti: true,
        kacakcilikBilgiBankasiYolcuIslemleri: true,
        kacakcilikBilgiBankasiOlayAciklama: true,
        takibeTakilanYolcular: true,
        takibeTakilanYolcularCiktiAlma: true,
        takibeTakilanYolcularDetayGoruntuleme: true,
        YolcuTakip: true,
        YolcuTakipEkle: true,
        YolcuTakipKaldir: true,
        onayBekleyenlerListesi: true,
        onayBekleyenlerListesiCiktiAlma: false,
        onayBekleyenlerListesiOnaylamaYetkisi: false,
        islemYapilanYolcularGoruntuleme: true,
        islemYapilanYolcularSilme: false,
        ZamanlanmisSorguListesiSorguEkleme: true,
        ZamanlanmisSorguListesiCiktiAlma: true,
        sikUcanYolcularSorgulama: true,
        sikUcanYolcularCiktriAlma: true,
        yolcuPuanlama: true,
        destekTalebi: true,
        destekTalebiGuncelle:false,
        havaTakip: true, //harita
        menu: []
    },
    "Uzak Kullanıcı": {
        "anasayfaYeniIsim": true,
        kullaniciGoruntule: false,
        "kullaniciEkle": false,
        "kullaniciSil": true,
        "kullaniciListesiCiktiAlma": false,
        kullaniciIslemleriIzleme: true,
        ilkDefaUcanlarSorgula: false,
        yeniYolcuCiktiAlma: false,
        yolcuIletisimBilgiler: true,
        yolcuIletisimBilgilerCiktiAlma: true,
        duyuruListesiGoruntule: true,
        duyuruEkle: false,
        duyuruOku: true,
        ilkAcilisDuyuruOnayla: true,
        ulkeListesiGoruntule: true,
        ulkeEkle: false,
        havalimaniListesiGoruntuleme: true,
        havalimaniListesiEkleme: false,
        islemTurleriYeniIsim: true,
        mesajAkis: true,
        mesajAkisCiktiAlma: false,
        logKaydi: true,
        ucusListesiYolculariGoster: true,
        ucusListesiKBBGoster: true,
        ucusListesiTakipliYolcuGoster: true,
        yolcuSorgulama: true,
        yolcuSorgulamaCiktiiAlma: true,
        kacakcilikBilgiBankasi: true,
        kacakcilikBilgiBankasiCiktiAlma: true,
        kacakcilikBilgiBankasiYolcuDetayi: true,
        kacakcilikBilgiBankasiYolcuBilgiKarti: true,
        kacakcilikBilgiBankasiYolcuBilgiKarti: true,
        kacakcilikBilgiBankasiYolcuIslemleri: true,
        kacakcilikBilgiBankasiOlayAciklama: true,
        takibeTakilanYolcular: true,
        takibeTakilanYolcularCiktiAlma: true,
        takibeTakilanYolcularDetayGoruntuleme: true,
        YolcuTakip: true,
        YolcuTakipEkle: true,
        YolcuTakipKaldir: true,
        onayBekleyenlerListesi: true,
        onayBekleyenlerListesiCiktiAlma: false,
        onayBekleyenlerListesiOnaylamaYetkisi: false,
        islemYapilanYolcularGoruntuleme: true,
        islemYapilanYolcularSilme: false,
        ZamanlanmisSorguListesiSorguEkleme: true,
        ZamanlanmisSorguListesiCiktiAlma: true,
        sikUcanYolcularSorgulama: true,
        sikUcanYolcularCiktriAlma: true,
        yolcuPuanlama: true,
        destekTalebi: true,
        destekTalebiGuncelle:false,
        havaTakip: true, //harita
        menu: []
    },
    "Komuta Kontrol": {
        "anasayfaYeniIsim": true,
        kullaniciGoruntule: false,
        "kullaniciEkle": false,
        "kullaniciSil": true,
        "kullaniciListesiCiktiAlma": false,
        kullaniciIslemleriIzleme: true,
        ilkDefaUcanlarSorgula: false,
        yeniYolcuCiktiAlma: false,
        yolcuIletisimBilgiler: true,
        yolcuIletisimBilgilerCiktiAlma: true,
        duyuruListesiGoruntule: true,
        duyuruEkle: false,
        duyuruOku: true,
        ilkAcilisDuyuruOnayla: true,
        ulkeListesiGoruntule: true,
        ulkeEkle: false,
        havalimaniListesiGoruntuleme: true,
        havalimaniListesiEkleme: false,
        islemTurleriYeniIsim: true,
        mesajAkis: true,
        mesajAkisCiktiAlma: false,
        logKaydi: true,
        ucusListesiYolculariGoster: true,
        ucusListesiKBBGoster: true,
        ucusListesiTakipliYolcuGoster: true,
        yolcuSorgulama: true,
        yolcuSorgulamaCiktiiAlma: true,
        kacakcilikBilgiBankasi: true,
        kacakcilikBilgiBankasiCiktiAlma: true,
        kacakcilikBilgiBankasiYolcuDetayi: true,
        kacakcilikBilgiBankasiYolcuBilgiKarti: true,
        kacakcilikBilgiBankasiYolcuBilgiKarti: true,
        kacakcilikBilgiBankasiYolcuIslemleri: true,
        kacakcilikBilgiBankasiOlayAciklama: true,
        takibeTakilanYolcular: true,
        takibeTakilanYolcularCiktiAlma: true,
        takibeTakilanYolcularDetayGoruntuleme: true,
        YolcuTakip: true,
        YolcuTakipEkle: true,
        YolcuTakipKaldir: true,
        onayBekleyenlerListesi: true,
        onayBekleyenlerListesiCiktiAlma: false,
        onayBekleyenlerListesiOnaylamaYetkisi: false,
        islemYapilanYolcularGoruntuleme: true,
        islemYapilanYolcularSilme: false,
        ZamanlanmisSorguListesiSorguEkleme: true,
        ZamanlanmisSorguListesiCiktiAlma: true,
        sikUcanYolcularSorgulama: true,
        sikUcanYolcularCiktriAlma: true,
        yolcuPuanlama: true,
        destekTalebi: true,
        destekTalebiGuncelle:false,
        havaTakip: true, //harita
        menu: ['map']
    },
}

