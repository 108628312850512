import { callbackManager, FilterManager } from "services/GlobalService"
import fetch from 'auth/FetchInterceptor'
import { SUPPORT, USER } from '../URL'
import { fileToBase64 } from "utils"
const Service = {}
const URL = SUPPORT

Service.list = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('offset', page.customOffset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)
    data.append('reportId', page?.ParentId ?? '')

    FilterManager(data, page?.filters)

    fetch({
        url: URL.LIST,
        method: 'POST',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Service.get_messages = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('offset', 0)
    data.append('limit', 999)
    data.append('reportId', page?.data?.ReportId ?? '')

    return fetch({
        url: URL.LIST,
        method: 'POST',
        data: data
    })
        .then((r) => r)
        .catch(() => {
            if (loading) loading(false)
        });
}

Service.receiver_list = async function (setData) {
    const data = new FormData()
    data.append('offset', 0)
    data.append('limit', 999)

    fetch({
        url: URL.RECEIVER,
        method: 'post',
        data: data
    })
        .then((r) => setData(r?.Model))
}

Service.edit = async function (user, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('name', user.name)
    data.append('surname', user.surname)
    data.append('email', user.email)
    data.append('tel', user.Tel)
    data.append('TcKimlikNo', user.TcKimlikNumarasi)
    data.append('OrganizationId', user.OrganizationId)


    fetch({
        url: URL.EDIT + user.id,
        method: 'put',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Service.edit2 = async function (user, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('Explanition', user.Explanition)
    data.append('ReportId', user.ReportId ?? '')
    data.append('ReportTo', user.ReportTo ?? '')
    data.append('idStr', user.ReportId ?? '')

    if (user?.EkBelge) {
        let f = await fileToBase64(user.EkBelge);
        data.append('EkBelge', f.split(",")[1])
    } else {
        data.append('EkBelge', "")
    }

    return fetch({
        url: URL.EDIT,
        method: 'put',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Service.add = async function (user, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('Explanition', user.Explanition)
    data.append('ReportId', user.ReportId ?? '')
    data.append('ReportTo', user.ReportTo ?? '')
    
    if (user?.EkBelge) {
        let f = await fileToBase64(user.EkBelge);
        data.append('EkBelge', f.split(",")[1])
    } else {
        data.append('EkBelge', "")
    }

    fetch({
        url: URL.ADD,
        method: 'POST',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Service.addRole = async function (item, setData, loading, cb) {
    if (loading) loading(true)
    try {
        for await (const id of item.RoleId) {
            const data = new FormData()
            data.append('UserId', item.UserId)
            data.append('RoleId', id)

            await fetch({
                url: "/Api/RoleObjModel",
                method: 'post',
                data: data
            })
        }
        callbackManager(null, setData, loading, cb)
    } catch (error) {
        if (loading) loading(false)
    }

}

Service.addGroup = async function (item, setData, loading, cb) {
    if (loading) loading(true)
    try {
        for await (const id of item.UserGroupId) {
            const data = new FormData()
            data.append('UserId', item.UserId)
            data.append('UserGroupId', id)

            await fetch({
                url: "/Api/RoleObjModel",
                method: 'post',
                data: data
            })
        }
        callbackManager(null, setData, loading, cb)
    } catch (error) {
        if (loading) loading(false)
    }

}

Service.delete = function (id, message) {
    if (!id) return;
    const formData = new FormData()
    formData.append('message', message)
    return fetch({
        url: URL.DELETE + id,
        method: 'delete',
        data: formData
    })
}


Service.logout = function () {
    return fetch({
        url: '/Authorazation/Logout',
        method: 'POST'
    })
}

export default Service