import { callbackManager, FilterManager } from "services/GlobalService"
import fetch from 'auth/FetchInterceptor'
import { TARGET_TRAVELLER } from '../URL'
import moment from "moment"

const TargetTraveller = {}
const URL = TARGET_TRAVELLER

TargetTraveller.list = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('offset', page.customOffset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)
    data.append('orderColumnAndDirection', 'Id')
    FilterManager(data, page?.filters)

    fetch({
        url: URL.LIST,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

TargetTraveller.self = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('offset', page.customOffset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)
    FilterManager(data, page.filters)

    fetch({
        url: URL.SELF,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

TargetTraveller.types = async function (setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('orderColumnAndDirection', 'Reserved2')
    data.append('offset', 0)
    data.append('limit', -1)

    fetch({
        url: URL.TYPES,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r.Model, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

TargetTraveller.add = function (item, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('Ad', item.Ad ?? '')
    data.append('Soyad', item.Soyad ?? '')
    data.append('DogumTarihi', moment(item.DogumTarihi).format('YYYY-MM-DD').split(" ")[0] ?? '')
    data.append('PasaportTcNo', item.PasaportTcNo)
    data.append('TakipTuruId', item.TakipTuru)
    data.append('Aciklama', item.Aciklama)
    data.append('TakipBitisTarihi', moment(item.TakipBitisTarihi).format('YYYY-MM-DD').split(" ")[0])
    data.append('UzatmaSebebi', item.UzatmaSebebi ?? '')
    data.append('GecikmesindeSakincaOlanHal', item.GecikmesindeSakincaOlanHal ? '1' : '0')
    if (item.EkBelge) {
        data.append('EkBelge', item.EkBelge.split(",")[1])
    }

    fetch({
        url: URL.ADD,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

TargetTraveller.delete = function (id, message) {
    if (!id) return;
    return fetch({
        url: URL.DELETE + id,
        method: 'delete'
    })
}

TargetTraveller.listNotes = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('offset', page?.customOffset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)
    data.append('KisiTakipId', page?.KisiTakipId ?? -1)
    data.append('orderColumnAndDirection', page?.orderColumnAndDirection ?? 1)
    FilterManager(data, page.filters)

    return fetch({
        url: URL.LIST_NOTES,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

TargetTraveller.addNote = function (item, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('KisiTakipId', item?.KisiTakipId ?? '')
    data.append('Note', item?.Note ?? '')


    return fetch({
        url: URL.ADD_NOTE,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

export default TargetTraveller